import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { NoteStore } from "./stores/NoteStore";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const notes = NoteStore.getNotes();
root.render(
  <React.StrictMode>
    <App notes={notes} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
