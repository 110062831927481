class NoteStoreClass {
  notes = [];

  constructor() {
    const initialNotes = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        name: "My first note",
        text: "Add details to your to-do notes",
        is_done: true,
      },
    ];
    const localStorageNotes = localStorage.getItem("notes");
    this.notes = JSON.parse(localStorageNotes) || initialNotes;
  }

  getNotes() {
    return this.notes;
  }
}

export const NoteStore = new NoteStoreClass();
