import React from "react";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Overlay(props) {
  const { onClose, open, noteDetail, editNoteDetail, setNoteDetail } = props;

  function handleSubmit() {
    if (noteDetail?.name.trim() !== "") {
      editNoteDetail(noteDetail);
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      {/* <DialogTitle></DialogTitle> */}
      <DialogContent>
        <div className="note">
          <Checkbox
            id={props.id}
            checked={noteDetail?.is_done}
            onChange={(e) =>
              setNoteDetail({ ...noteDetail, is_done: e.target.checked })
            }
          />
          <TextField
            id={props.id}
            className="note-edit"
            autoComplete="off"
            value={noteDetail?.name}
            label="Name"
            variant="standard"
            onChange={(e) =>
              setNoteDetail({ ...noteDetail, name: e.target.value })
            }
            multiline
          />
        </div>
        <div className="note-detail">
          <TextField
            id={props.id}
            className="note-edit"
            autoComplete="off"
            value={noteDetail?.text}
            label="Text"
            onChange={(e) =>
              setNoteDetail({ ...noteDetail, text: e.target.value })
            }
            multiline
            minRows={3}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label="cancel"
          onClick={onClose}
          startIcon={<CancelIcon />}
          sx={{ color: "grey" }}
        >
          Cancel
        </Button>
        <Button
          aria-label="save"
          disabled={noteDetail?.name.trim() === ""}
          onClick={handleSubmit}
          startIcon={<SaveIcon />}
          sx={{ color: "grey" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
