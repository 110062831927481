import React from "react";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";

export default function Splash({ openSplash, setOpenSplash }) {
  function handleDismiss() {
    localStorage.setItem("splash", "true");
    setOpenSplash(false);
  }

  return (
    <Dialog open={openSplash} fullWidth={true} maxWidth="md">
      <Box className="splash-header">
        <ListAltIcon sx={{ fontSize: 128 }} />
      </Box>
      <DialogContent>
        <div className="splash-content">
          <div className="splash-content-header">
            Remember your to-dos, and save your notes
          </div>
          <div className="splash-content-body">
            Don't forget what's important to you, and keep your productivity
            going.
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label="dismiss"
          onClick={handleDismiss}
          sx={{ color: "grey" }}
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
