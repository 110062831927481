import React, { useState } from "react";
import { Checkbox, IconButton, TextField, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Note(props) {
  const [isEditing, setEditing] = useState(false);
  const [newName, setNewName] = useState(props.name);

  function handleCancel() {
    setNewName(props.name);
    setEditing(false);
  }

  function handleKeyDown(e) {
    // Ctrl/Cmd + Enter
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      handleSubmit(e);
    }
    // Esc
    else if (e.key === "Escape") {
      handleCancel(e);
    }
  }

  function handleSubmit() {
    if (newName.trim() !== "") {
      props.editNote(props.id, newName);
      setNewName(newName);
      setEditing(false);
    }
  }

  const editingTemplate = (
    <>
      <div className="note-buttons">
        <Checkbox
          id={props.id}
          checked={props.is_done}
          onChange={() => props.toggleNoteDone(props.id)}
        />
      </div>
      <div className="note-content">
        <TextField
          id={props.id}
          className="note-edit"
          autoComplete="off"
          value={newName}
          variant="standard"
          onChange={(e) => setNewName(e.target.value)}
          onKeyDown={handleKeyDown}
          multiline
        />
      </div>
      <div className="note-buttons">
        <Tooltip title="Save">
          <IconButton
            aria-label="save"
            disabled={newName.trim() === ""}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton aria-label="cancel" onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
  const viewTemplate = (
    <>
      <div className="note-buttons">
        <Checkbox
          id={props.id}
          checked={props.is_done}
          onChange={() => props.toggleNoteDone(props.id)}
        />
      </div>
      <div
        className={
          props.is_done ? "note-content note-content-done" : "note-content"
        }
        onClick={() => setEditing(true)}
      >
        <label className="todo-label" htmlFor={props.id}>
          {props.name}
        </label>
      </div>
      <div className="note-buttons">
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            onClick={() => props.openEditNoteDetail(props)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => props.deleteNote(props.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );

  return (
    <div className="note">{isEditing ? editingTemplate : viewTemplate}</div>
  );
}
