import { useEffect } from "react";

export default function useExternalScripts({
  url,
  isAsync = false,
  crossOrigin = "",
}) {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    if (isAsync) script.async = true;
    script.setAttribute("src", url);
    if (crossOrigin) script.setAttribute("crossorigin", crossOrigin);

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [url, isAsync, crossOrigin]);
}
