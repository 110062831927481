import React, { useState } from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function Form(props) {
  const [name, setName] = useState("");

  const handleKeyDown = (e) => {
    // Ctrl/Cmd + Enter
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
      handleSubmit(e);
    }
    // Esc
    else if (e.key === "Escape") {
      setName("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim() !== "") {
      props.addNote(name.trim());
      setName("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="div-input-add">
        <TextField
          id="txtAdd"
          name="txtAdd"
          className="input-add"
          autoComplete="off"
          value={name}
          label="What do you need to do?"
          onChange={(e) => setName(e.target.value)}
          onKeyDown={handleKeyDown}
          multiline
        />
        <div>
          <Tooltip title="Add">
            <div>
              {/* A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
              <IconButton
                aria-label="add"
                className="btn-add"
                disabled={name.trim() === ""}
                onClick={handleSubmit}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
    </form>
  );
}

export default Form;
