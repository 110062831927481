import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import "./App.css";
import Form from "./components/Form";
import Note from "./components/Note";
import NoteList from "./components/NoteList";
import Overlay from "./components/Overlay";
import Splash from "./components/Splash";
import useExternalScripts from "./hooks/useExternalScripts";

function App(props) {
  const [notes, setNotes] = useState(props.notes);
  const [openSplash, setOpenSplash] = useState(
    localStorage.getItem("splash") === null
  );
  const [openOverlay, setOpenOverlay] = useState(false);
  const [noteDetail, setNoteDetail] = useState(null);

  const noteList = notes?.map((note) => (
    <Note
      key={note.id}
      id={note.id}
      name={note.name}
      text={note.text}
      is_done={note.is_done}
      editNote={editNote}
      openEditNoteDetail={openEditNoteDetail}
      toggleNoteDone={toggleNoteDone}
      deleteNote={deleteNote}
    />
  ));

  function addNote(name) {
    const newNote = { id: uuidv4(), name, is_done: false };
    setNotes([...notes, newNote]);
  }

  function openEditNoteDetail(note) {
    setOpenOverlay(true);
    setNoteDetail(note);
  }

  function handleOverlayClose() {
    setOpenOverlay(false);
  }

  function editNote(id, newName) {
    const editedNoteList = notes.map((note) => {
      // if this note has the same ID as the edited note
      if (id === note.id) {
        return { ...note, name: newName };
      }
      return note;
    });
    setNotes(editedNoteList);
  }

  function editNoteDetail(newNote) {
    const editedNoteList = notes.map((note) => {
      // if this note has the same ID as the edited note
      if (newNote.id === note.id) {
        return newNote;
      }
      return note;
    });
    setNotes(editedNoteList);
  }

  function toggleNoteDone(id) {
    const updatedNotes = notes.map((note) => {
      // if this note has the same ID as the edited note
      if (id === note.id) {
        // use object spread to make a new object
        // whose `is_done` prop has been inverted
        return { ...note, is_done: !note.is_done };
      }
      return note;
    });
    setNotes(updatedNotes);
  }

  function deleteNote(id) {
    const remainingNotes = notes.filter((note) => id !== note.id);
    setNotes(remainingNotes);
  }

  useEffect(() => {
    localStorage.setItem("notes", JSON.stringify(notes));
  }, [notes]);

  useExternalScripts({
    url: "https://www.googletagmanager.com/gtag/js?id=G-L606K9T4LV",
    isAsync: true,
  });

  useExternalScripts({
    url: "js/googletag.js",
  });

  useExternalScripts({
    url: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2338152560044715",
    isAsync: true,
    crossOrigin: "anonymous",
  });

  return (
    <div className="App">
      <Splash openSplash={openSplash} setOpenSplash={setOpenSplash}></Splash>
      <Overlay
        open={openOverlay}
        noteDetail={noteDetail}
        editNoteDetail={editNoteDetail}
        setNoteDetail={setNoteDetail}
        onClose={handleOverlayClose}
      ></Overlay>
      <header className="App-header">
        <div>
          {/* <a
            href="https://duffybugs.com"
            target="_blank"
            rel="noopener noreferrer"> */}
          <img src="favicon.ico" className="App-logo" alt="logo" />
          {/* </a> */}
        </div>
        <div>To-do Notes</div>
        <Tooltip title="About">
          <IconButton aria-label="about" onClick={setOpenSplash}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </header>

      <div className="App-body">
        <NoteList notes={noteList} />
      </div>
      <footer className="App-footer">
        <Form addNote={addNote} />
      </footer>
    </div>
  );
}

export default App;
